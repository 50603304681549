// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-modules-fengshui-bazhai-chart-js": () => import("./../../../src/modules/fengshui/bazhai/chart.js" /* webpackChunkName: "component---src-modules-fengshui-bazhai-chart-js" */),
  "component---src-modules-fengshui-bazi-chart-js": () => import("./../../../src/modules/fengshui/bazi/chart.js" /* webpackChunkName: "component---src-modules-fengshui-bazi-chart-js" */),
  "component---src-modules-fengshui-calendar-page-js": () => import("./../../../src/modules/fengshui/calendar/page.js" /* webpackChunkName: "component---src-modules-fengshui-calendar-page-js" */),
  "component---src-modules-fengshui-flyingstar-chart-js": () => import("./../../../src/modules/fengshui/flyingstar/chart.js" /* webpackChunkName: "component---src-modules-fengshui-flyingstar-chart-js" */),
  "component---src-modules-fengshui-iching-index-js": () => import("./../../../src/modules/fengshui/iching/index.js" /* webpackChunkName: "component---src-modules-fengshui-iching-index-js" */),
  "component---src-modules-fengshui-index-js": () => import("./../../../src/modules/fengshui/index.js" /* webpackChunkName: "component---src-modules-fengshui-index-js" */),
  "component---src-modules-fengshui-library-list-js": () => import("./../../../src/modules/fengshui/library/list.js" /* webpackChunkName: "component---src-modules-fengshui-library-list-js" */),
  "component---src-modules-fengshui-other-subscription-js": () => import("./../../../src/modules/fengshui/other/subscription.js" /* webpackChunkName: "component---src-modules-fengshui-other-subscription-js" */),
  "component---src-modules-fengshui-qmdj-common-js": () => import("./../../../src/modules/fengshui/qmdj/common.js" /* webpackChunkName: "component---src-modules-fengshui-qmdj-common-js" */),
  "component---src-modules-fengshui-qmdj-day-js": () => import("./../../../src/modules/fengshui/qmdj/day.js" /* webpackChunkName: "component---src-modules-fengshui-qmdj-day-js" */),
  "component---src-modules-fengshui-qmdj-finder-js": () => import("./../../../src/modules/fengshui/qmdj/finder.js" /* webpackChunkName: "component---src-modules-fengshui-qmdj-finder-js" */),
  "component---src-modules-fengshui-qmdj-hour-js": () => import("./../../../src/modules/fengshui/qmdj/hour.js" /* webpackChunkName: "component---src-modules-fengshui-qmdj-hour-js" */),
  "component---src-modules-fengshui-qmdj-js": () => import("./../../../src/modules/fengshui/qmdj.js" /* webpackChunkName: "component---src-modules-fengshui-qmdj-js" */),
  "component---src-modules-fengshui-qmdj-month-js": () => import("./../../../src/modules/fengshui/qmdj/month.js" /* webpackChunkName: "component---src-modules-fengshui-qmdj-month-js" */),
  "component---src-modules-fengshui-qmdj-year-js": () => import("./../../../src/modules/fengshui/qmdj/year.js" /* webpackChunkName: "component---src-modules-fengshui-qmdj-year-js" */),
  "component---src-modules-fengshui-store-product-js": () => import("./../../../src/modules/fengshui/store/product.js" /* webpackChunkName: "component---src-modules-fengshui-store-product-js" */),
  "component---src-modules-fengshui-videos-channel-js": () => import("./../../../src/modules/fengshui/videos/channel.js" /* webpackChunkName: "component---src-modules-fengshui-videos-channel-js" */),
  "component---src-modules-fengshui-videos-index-js": () => import("./../../../src/modules/fengshui/videos/index.js" /* webpackChunkName: "component---src-modules-fengshui-videos-index-js" */),
  "component---src-modules-fengshui-videos-request-js": () => import("./../../../src/modules/fengshui/videos/request.js" /* webpackChunkName: "component---src-modules-fengshui-videos-request-js" */),
  "component---src-modules-fengshui-videos-view-js": () => import("./../../../src/modules/fengshui/videos/view.js" /* webpackChunkName: "component---src-modules-fengshui-videos-view-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

